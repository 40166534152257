import React from 'react';
import './specialnews.css';
import featuredImg from '../../assets/specialolympics.jpg';
import { Link } from 'react-router-dom';


const Specialnews = () => {
    const showSpecial = true;

    // below are for basketball individual skill team
    const photoLink2 = "https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2Fsummer_camp_2024%2FSUMMER%20CAMP%202024w.png?alt=media&token=fae7073a-4db0-405f-8a3b-2f8eaf19de86";
    const photoLink = "https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2Fsummer_camp_2024%2FSUMMER%20CAMP%202024%20(1).png?alt=media&token=f0599137-3c3e-4976-bbb9-9665d8f2d1bb";
    const dataContent = {
        link: "2025-01-28T04:31:08.267Z_Special_Ol_5050",
    }

    // below are for nordic ski camp
    const photo2aLink = "https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2Fnordic_ski%2F2025_2.jpg?alt=media&token=73d87c3e-5fc3-4b51-8a2f-076c8094db7a";
    const photo2bLink = "https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2Fnordic_ski%2FNordic%20ski%20banner_w2.jpg?alt=media&token=bc2c32da-9310-48b2-8f06-4cfd94dced84";
    const data2Content = {
        link: "2025-02-11T04:13:18.659Z_Nordic_Ski_7446",
    }

    // below is for Reading Club
    const data3Content = {
        link: "2024-12-03T03:42:02.785Z_Writing_Co_2278",
    }

    // below is for talent show
    const data5Content = {
        link: "2025-02-12T18:52:45.800Z_Volunteer__9261",
    }

    // below is for posture
    const data6Content = {
        link: "2024-12-10T17:51:20.090Z_Posture_an_3299",
    }

    if (showSpecial) {
        return (

            <>
                {/* below are for list of special events */}

                <div className="specialnewslist">

                    
                    <div className="specialnewslist-title-container">
                        <div className="specialnewslist-title">
                            Upcoming Special Events
                        </div>
                    </div>
                    <div className="specialnewslist-container">
                    
                        {/* <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Aug 10 (Sat)</div>
                            <div className="specialnewslist-button-title">
                                <Link to="/upcomingweekly" state={{ data: data6Content }}>
                                    Autism Lecture for Parents
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                                <Link to="/upcomingweekly" state={{ data: data6Content }}>
                                <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/event_upcomings%2F2024-08-04T23%3A41%3A27.660Z_0_4183?alt=media&token=7b203b6f-34ca-4244-b008-5cb8cbc9a1eb" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div> */}
                        
                        {/* <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Jul 30 (Feb)</div>
                            <div className="specialnewslist-button-title">
                                <Link to="/upcomingweekly" state={{ data: data5Content }}>
                                    Movie Night
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                                <Link to="/upcomingweekly" state={{ data: data5Content }}>
                                <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2F2024-07-28T22_28_08.071Z_0_1339a.jpg?alt=media&token=65cda1bc-caae-41ae-840a-e6ed9d9a2ce9" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div> */}

                        {/* <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Jul 14 (Sun)</div>
                            <div className="specialnewslist-button-title">
                                <Link to="/upcomingweekly" state={{ data: data2Content }}>
                                    Wild Waves Park
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                                <Link to="/upcomingweekly" state={{ data: data2Content }}>
                                <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2Fwildwave2.png?alt=media&token=cac1e46d-4e06-41b3-9a7e-7290dd67663a" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div> */}

                        {/* <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Oct 27 (Sun)</div>
                            <div className="specialnewslist-button-title">
                            <Link to="/upcomingweekly" state={{ data: data5Content }}>
                                    Halloween Party
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                            <Link to="/upcomingweekly" state={{ data: data5Content }}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2FWeixin%20Image_20241019110627a.jpg?alt=media&token=00f25af1-db61-44ce-95fb-1e7c236360ab" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div> */}
                        
                        <div className="specialnewslist-cell-empty"></div>

                        <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Feb 16 (Sun)</div>
                            <div className="specialnewslist-button-title">
                                <Link to="/upcomingweekly" state={{ data: data2Content }}>
                                    Nordic Ski Camp
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                                <Link to="/upcomingweekly" state={{ data: data2Content }}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2Fnordic_ski%2FSki%20poster_w2.jpg?alt=media&token=7f2139d5-6f80-4c4c-a0da-47726e479633" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div>

                        {/* <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Oct 28 (Mon)</div>
                            <div className="specialnewslist-button-title">
                                <Link to="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2FGemini_Generated_Image_je3vvvje3vvvje3v.jpg?alt=media&token=4a7d105a-2df1-412b-9369-caba81f150cb">
                                Meet Cha
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                                <Link to="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2FGemini_Generated_Image_je3vvvje3vvvje3v.jpg?alt=media&token=4a7d105a-2df1-412b-9369-caba81f150cb">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2FGemini_Generated_Image_je3vvvje3vvvje3va.jpg?alt=media&token=dd44bc3a-c7b5-4d9a-84e2-c4c1899b6ee7" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div> */}

                        <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Feb 17 (Mon)</div>
                            <div className="specialnewslist-button-title">
                            <Link to="/upcomingweekly" state={{ data: data5Content }}>
                                    Volunteer Training
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                            <Link to="/upcomingweekly" state={{ data: data5Content }}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/event_upcomings%2F2024-10-17T03%3A38%3A37.819Z_0_3015?alt=media&token=b495c7e0-8ac7-496b-9462-f02ab1e14882" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div>

                        {/* <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">By Dec 31</div>
                            <div className="specialnewslist-button-title">
                            <Link to="/upcomingweekly" state={{ data: data3Content }}>
                                    Writing Competition
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                            <Link to="/upcomingweekly" state={{ data: data3Content }}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2Fwriting%20competition%202.png?alt=media&token=bfd340c1-75e9-4424-b530-4cf6103be440" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div> */}

                        {/* <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Nov 17 (Sun)</div>
                            <div className="specialnewslist-button-title">
                            <Link to="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2FWeixin%20Image_20241030204746a.png?alt=media&token=959ac5e4-17ca-4c6d-ba6e-dd76222e4998">
                                    IB Swim Meet
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                            <Link to="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2FWeixin%20Image_20241030204746a.png?alt=media&token=959ac5e4-17ca-4c6d-ba6e-dd76222e4998">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2FWeixin%20Image_20241030204746b.jpg?alt=media&token=0a622f45-3424-4d02-8a8f-d0043e0aec61" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div> */}

                        {/* <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Jan 20</div>
                            <div className="specialnewslist-button-title">
                                <Link to="/upcomingweekly" state={{ data: data2Content }}>
                                    IB Training 2025Q1
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                                <Link to="/upcomingweekly" state={{ data: data2Content }}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/event_upcomings%2F2024-10-17T03%3A38%3A37.819Z_0_3015?alt=media&token=b495c7e0-8ac7-496b-9462-f02ab1e14882" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div> */}

                        {/* <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Jan 20</div>
                            <div className="specialnewslist-button-title">
                                <Link to="/upcomingweekly" state={{ data: data5Content }}>
                                    Talent Show & Holiday Party
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                                <Link to="/upcomingweekly" state={{ data: data5Content }}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2FWeixin%20Image_20241211091751a.jpg?alt=media&token=157b93cb-5cb9-4b4d-ac8e-89bac3ce4688" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div> */}

                        {/* <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Nov 24 (Sun)</div>
                            <div className="specialnewslist-button-title">
                                <Link to="/upcomingweekly" state={{ data: data5Content }}>
                                Thanksgiving Potluck
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                                <Link to="/upcomingweekly" state={{ data: data5Content }}>
                                    <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2Fthanksgiving%2FWeixin%20Image_20241116225124a.jpg?alt=media&token=0f0f7513-1502-41b9-a20e-5399d126485f" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div> */}
                        
                        <div className="specialnewslist-cell-empty"></div>
                        
                        {/* <div className="specialnewslist-cell">
                            <div className="specialnewslist-button-date">Sep 23 (Mon)</div>
                            <div className="specialnewslist-button-title">
                                <Link to="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2FWeixin%20Image_20240916220650.jpg?alt=media&token=286ad748-14d2-4cad-89f9-84cdba961ca5">
                                Special Education
                                </Link>
                            </div>
                            <div className="specialnewslist-button">
                                <Link to="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2FWeixin%20Image_20240916220650.jpg?alt=media&token=286ad748-14d2-4cad-89f9-84cdba961ca5">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/special_events%2FWeixin%20Image_20240916220650a.jpg?alt=media&token=02c9b02f-05d8-4e27-b556-71d3f36e1c99" width="100%" alt="featured" />
                                </Link>
                            </div>
                        </div> */}





                    </div>
                </div>

                {/* below are for nordic ski camp banner */}
                {/* <div className="specialnews3a">
                    <Link to="/upcomingweekly" state={{ data: data2Content }}>
                        <img src={photo2bLink} width="100%" alt="featured" />
                    </Link>
                </div>
                <div className="specialnews3b">
                    <Link to="/upcomingweekly" state={{ data: data2Content }}>
                        <img src={photo2aLink} width="100%" alt="featured" />
                    </Link>
                </div> */}
                

                {/* below are for summer camp banner */}
                {/* <div className="specialnews2a">
                    <Link to="/upcomingweekly" state={{ data: dataContent }}>
                        <div className="specialnews-blue-button">
                            <img src={photoLink2} width="800" alt="featured" />
                        </div>
                    </Link>
                </div>
                <div className="specialnews2b">
                    <Link to="/upcomingweekly" state={{ data: dataContent }}>
                        <div className="specialnews-blue-button">
                            <img src={photoLink} width="350" alt="featured" />
                        </div>
                    </Link>
                </div> */}


                {/* below are for special olympics */}
                <div className="specialnews">

                    <div className="specialnews-so">
                        <Link to="/specialolympics">
                        <img src={featuredImg} width="300" alt="featured" />
                        </Link>
                    </div>

                    {/* <div className="specialnews-title">
                        <Link to="/upcomingweekly" state={{ data: dataContent }}>
                            Basketball Individual Skill Team
                        </Link>
                    </div> */}

                    {/* <div className="specialnews-title">
                        <Link to="/upcomingweekly" state={{ data: data3Content }}>
                            Track & Field Program
                        </Link>
                    </div> */}

                </div>
            </>

        )
    }

}

export default Specialnews